import YoutubePlayer from "../YoutubePlayer/YoutubePlayer";

const RandomVibrateSound = (): JSX.Element | null => {
  const value = Math.random();

  if (value < 0.9) {
    return null;
  }

  return <YoutubePlayer id="h3axmXESErA" autoplay />;
};

export default RandomVibrateSound;
